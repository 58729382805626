import { HttpClient } from '@angular/common/http'
import { Injectable, inject } from '@angular/core'
import { API_URL_V2_VERSION, HEADER_API_KEY } from '@navix/utils/tokens'
import { Observable } from 'rxjs'
import { GetEmailTemplatesResponse } from '../domain/get-email-templates.response'
import { AddEmailTemplateRequest } from '../domain/add-email-template.request'
import { platform_v2 } from '@navix/api-models'

@Injectable({
  providedIn: 'root'
})
export class DisputesService {
  private readonly _http = inject(HttpClient)
  private readonly apiV2Url = inject(API_URL_V2_VERSION)
  private readonly apiKey = inject(HEADER_API_KEY)

  private readonly headersWithApiKey: { [key: string]: string } = {
    ...this.apiKey
  } as { [key: string]: string }

  getEmailTemplates(): Observable<GetEmailTemplatesResponse> {
    return this._http.get<GetEmailTemplatesResponse>(
      `${this.apiV2Url}email/templates`,
      {
        headers: this.headersWithApiKey
      }
    )
  }

  addEmailTemplate(request: AddEmailTemplateRequest) {
    return this._http.post<void>(`${this.apiV2Url}email/templates`, request, {
      headers: this.headersWithApiKey
    })
  }

  updateEmailTemplate(
    emailTempalteId: string,
    request: AddEmailTemplateRequest
  ) {
    return this._http.put<void>(
      `${this.apiV2Url}email/templates/${emailTempalteId}`,
      request,
      {
        headers: this.headersWithApiKey
      }
    )
  }

  deleteEmailTemplate(emailTempalteId: string) {
    return this._http.delete<void>(
      `${this.apiV2Url}email/templates/${emailTempalteId}`,
      {
        headers: this.headersWithApiKey
      }
    )
  }
}
