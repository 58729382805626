import { LoadingStatuses, updateLoadingState } from '@navix/shared/loading'
import { on } from '@ngrx/store'
import { DisputeReasonAsyncOperations } from '../../../domain/dispute-reasons/dispute-reasons-loading.model'
import { DisputeReasonsState } from '../dispute-reasons.reducer'
import { addDisputeReasonsActions } from './add-dispute-reasons.actions'

export const addDisputeReasonsReducers = [
  on(
    addDisputeReasonsActions.addDisputeReasons,
    (state: DisputeReasonsState) => ({
      ...state,
      loading: updateLoadingState(state.loading, {
        operation: DisputeReasonAsyncOperations.addDisputeReasons,
        status: LoadingStatuses.InProgress
      })
    })
  ),
  on(
    addDisputeReasonsActions.addDisputeReasonsSuccess,
    (state: DisputeReasonsState): DisputeReasonsState => ({
      ...state,
      loading: updateLoadingState(state.loading, {
        operation: DisputeReasonAsyncOperations.addDisputeReasons,
        status: LoadingStatuses.Completed,
        message: 'Dispute reasons added successfully.'
      })
    })
  ),
  on(
    addDisputeReasonsActions.addDisputeReasonsFail,
    (state: DisputeReasonsState, { error }): DisputeReasonsState => ({
      ...state,
      loading: updateLoadingState(state.loading, {
        operation: DisputeReasonAsyncOperations.addDisputeReasons,
        status: LoadingStatuses.Failed,
        message:
          error ??
          'There was an error trying to add dispute reasons. Please retry.'
      })
    })
  ),
  on(
    addDisputeReasonsActions.resetAddDisputeReasonsLoadingState,
    (state: DisputeReasonsState): DisputeReasonsState => ({
      ...state,
      loading: updateLoadingState(state.loading, {
        operation: DisputeReasonAsyncOperations.addDisputeReasons,
        status: LoadingStatuses.NotStarted
      })
    })
  )
]
