import { LoadingStatuses, updateLoadingState } from '@navix/shared/loading'
import { on } from '@ngrx/store'
import { DisputeReasonGroupAsyncOperations } from '../../../domain/dispute-reason-groups/dispute-reasons-loading.model'
import { DisputeReasonGroupsState } from '../dispute-reason-groups.reducer'
import { addDisputeReasonGroupsActions } from './add-dispute-reason-groups.actions'

export const addDisputeReasonGroupsReducers = [
  on(
    addDisputeReasonGroupsActions.addDisputeReasonGroups,
    (state: DisputeReasonGroupsState) => ({
      ...state,
      loading: updateLoadingState(state.loading, {
        operation: DisputeReasonGroupAsyncOperations.addDisputeReasonGroups,
        status: LoadingStatuses.InProgress
      })
    })
  ),
  on(
    addDisputeReasonGroupsActions.addDisputeReasonGroupsSuccess,
    (state: DisputeReasonGroupsState): DisputeReasonGroupsState => ({
      ...state,
      loading: updateLoadingState(state.loading, {
        operation: DisputeReasonGroupAsyncOperations.addDisputeReasonGroups,
        status: LoadingStatuses.Completed,
        message: 'Dispute reason groups added successfully.'
      })
    })
  ),
  on(
    addDisputeReasonGroupsActions.addDisputeReasonGroupsFail,
    (state: DisputeReasonGroupsState, { error }): DisputeReasonGroupsState => ({
      ...state,
      loading: updateLoadingState(state.loading, {
        operation: DisputeReasonGroupAsyncOperations.addDisputeReasonGroups,
        status: LoadingStatuses.Failed,
        message:
          error ??
          'There was an error trying to add dispute reason groups. Please retry.'
      })
    })
  ),
  on(
    addDisputeReasonGroupsActions.resetAddDisputeReasonGroupsLoadingState,
    (state: DisputeReasonGroupsState): DisputeReasonGroupsState => ({
      ...state,
      loading: updateLoadingState(state.loading, {
        operation: DisputeReasonGroupAsyncOperations.addDisputeReasonGroups,
        status: LoadingStatuses.NotStarted
      })
    })
  )
]
