import { createActionGroup, emptyProps, props } from '@ngrx/store'
import { DisputeReason } from '../../../domain/dispute-reasons/dispute-reason.model'

export const updateDisputeReasonActions = createActionGroup({
  source: 'Dispute Reason - Update',
  events: {
    updateDisputeReason: props<{
      disputeReason: DisputeReason
    }>(),
    updateDisputeReasonSuccess: emptyProps(),
    updateDisputeReasonFail: props<{ error: unknown }>(),
    resetUpdateDisputeReasonLoadingState: emptyProps()
  }
})
