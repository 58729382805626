import { LoadingState } from '@navix/shared/loading'

export enum DisputeReasonGroupAsyncOperations {
  loadDisputeReasonGroups = 'loadDisputeReasonGroups',
  addDisputeReasonGroups = 'addDisputeReasonGroups',
  updateDisputeReasonGroup = 'updateDisputeReasonGroup'
}

export type DisputeReasonGroupsLoadingState = LoadingState<
  typeof DisputeReasonGroupAsyncOperations
>
