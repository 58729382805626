import { Injectable, inject } from '@angular/core'
import {
  FormArray,
  FormGroup,
  NonNullableFormBuilder,
  Validators
} from '@angular/forms'
import { DisputeReasonGroupFormControls } from '../domain/dispute-reason-groups/dispute-reason-group-form.model'

@Injectable({
  providedIn: 'root'
})
export class DisputeReasonGroupsFormService {
  private readonly fb = inject(NonNullableFormBuilder)

  getDisputeReasonGroupsForm(): FormArray<
    FormGroup<DisputeReasonGroupFormControls>
  > {
    return this.fb.array<FormGroup<DisputeReasonGroupFormControls>>([
      this.getDisputeReasonGroupForm()
    ])
  }

  getDisputeReasonGroupForm(): FormGroup<DisputeReasonGroupFormControls> {
    return this.fb.group({
      id: this.fb.control<string | null>(null),
      description: this.fb.control<string>('', Validators.required),
      allowEmail: this.fb.control<boolean>(true, Validators.required),
      eTag: this.fb.control<string | null>(null)
    })
  }
}
