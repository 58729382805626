import { HttpErrorResponse } from '@angular/common/http'
import { inject } from '@angular/core'
import { platform_v2 } from '@navix/api-models'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { catchError, debounceTime, exhaustMap, map, of } from 'rxjs'
import { MS_BEFORE_RESET_LOADING_STATE } from '../../disputes.constants'
import { addDisputeReasonGroupsActions } from './add-dispute-reason-groups.actions'

export const addDisputeReasonGroups = createEffect(
  (
    actions$ = inject(Actions),
    service = inject(platform_v2.DisputeService)
  ) => {
    return actions$.pipe(
      ofType(addDisputeReasonGroupsActions.addDisputeReasonGroups),
      exhaustMap(({ disputeReasonGroups }) =>
        service
          .postApiV2DisputeReasonGroupsMultiple({
            requestBody: disputeReasonGroups.map(x => {
              return {
                description: x.description,
                allowEmail: x.allowEmail
              } as platform_v2.Navix_Services_Sdk_DisputeReasonGroupCreationDTO
            })
          })
          .pipe(
            map(() =>
              addDisputeReasonGroupsActions.addDisputeReasonGroupsSuccess()
            ),
            catchError(error => {
              let errorMessage
              if (error instanceof HttpErrorResponse) {
                if (error.status === 409) {
                  errorMessage = 'Some dispute reason groups already exist.'
                } else {
                  errorMessage =
                    'An error occurred while processing your request.'
                }
              }
              console.error(
                'There was an error trying to add the dispute reason groups',
                error
              )
              return of(
                addDisputeReasonGroupsActions.addDisputeReasonGroupsFail({
                  error: errorMessage
                })
              )
            })
          )
      )
    )
  },
  {
    functional: true
  }
)

export const resetAddDisputeReasonGroups = createEffect(
  (actions$ = inject(Actions)) => {
    return actions$.pipe(
      ofType(
        addDisputeReasonGroupsActions.addDisputeReasonGroupsSuccess,
        addDisputeReasonGroupsActions.addDisputeReasonGroupsFail
      ),
      debounceTime(MS_BEFORE_RESET_LOADING_STATE),
      map(() =>
        addDisputeReasonGroupsActions.resetAddDisputeReasonGroupsLoadingState()
      )
    )
  },
  {
    functional: true
  }
)
