import { createActionGroup, emptyProps, props } from '@ngrx/store'
import { EmailTemplate } from '../../../domain/email-template.model'

export const updateEmailTemplatesActions = createActionGroup({
  source: 'Update Email Templates',
  events: {
    updateEmailTemplate: props<{
      emailTemplate: EmailTemplate
    }>(),
    updateEmailTemplateSuccess: emptyProps(),
    updateEmailTemplateFail: props<{ error: unknown }>(),
    resetUpdateEmailTemplateLoadingState: emptyProps()
  }
})
