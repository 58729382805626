import { createActionGroup, emptyProps, props } from '@ngrx/store'

export const deleteEmailTemplatesActions = createActionGroup({
  source: 'Email Templates - Delete',
  events: {
    deleteEmailTemplate: props<{ id: string }>(),
    deleteEmailTemplateSuccess: emptyProps(),
    deleteEmailTemplateFail: props<{ error: unknown }>(),
    resetDeleteEmailTemplateLoadingState: emptyProps()
  }
})
