import { inject } from '@angular/core'
import { platform_v2 } from '@navix/api-models'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { catchError, debounceTime, exhaustMap, map, of } from 'rxjs'
import { fromGetDisputeReasonGroupsResponse } from '../../../adapter/FromGetDisputeReasonGroupsResponse'
import { MS_BEFORE_RESET_LOADING_STATE } from '../../disputes.constants'
import { loadDisputeReasonGroupsActions } from './load-dispute-reason-groups.actions'

export const loadDisputeReasonGroups = createEffect(
  (
    actions$ = inject(Actions),
    service = inject(platform_v2.DisputeService)
  ) => {
    return actions$.pipe(
      ofType(loadDisputeReasonGroupsActions.loadDisputeReasonGroups),
      exhaustMap(() =>
        service.getApiV2DisputeReasonGroups().pipe(
          map(response =>
            loadDisputeReasonGroupsActions.loadDisputeReasonGroupsSuccess({
              disputeReasonGroups: fromGetDisputeReasonGroupsResponse(response)
            })
          ),
          catchError(error =>
            of(
              loadDisputeReasonGroupsActions.loadDisputeReasonGroupsFail({
                error
              })
            )
          )
        )
      )
    )
  },
  {
    functional: true
  }
)

export const resetGetDisputeReasonGroups = createEffect(
  (actions$ = inject(Actions)) => {
    return actions$.pipe(
      ofType(
        loadDisputeReasonGroupsActions.loadDisputeReasonGroupsSuccess,
        loadDisputeReasonGroupsActions.loadDisputeReasonGroupsFail
      ),
      debounceTime(MS_BEFORE_RESET_LOADING_STATE),
      map(() =>
        loadDisputeReasonGroupsActions.resetGetDisputeReasonGroupsLoadingState()
      )
    )
  },
  {
    functional: true
  }
)
