import { on } from '@ngrx/store'
import { deleteEmailTemplatesActions } from './delete-email-templates.actions'
import { EmailTemplatesState } from '../email-templates.reducer'
import { LoadingStatuses, updateLoadingState } from '@navix/shared/loading'
import { AsyncOperations } from '../../../domain/disputes-loading.model'

export const deleteEmailTemplatesReducers = [
  on(
    deleteEmailTemplatesActions.deleteEmailTemplateSuccess,
    (state: EmailTemplatesState): EmailTemplatesState => ({
      ...state,
      loading: updateLoadingState(state.loading, {
        operation: AsyncOperations.deleteEmailTemplate,
        status: LoadingStatuses.Completed,
        message: 'Email template deleted successfully.'
      })
    })
  ),
  on(
    deleteEmailTemplatesActions.deleteEmailTemplateFail,
    (state: EmailTemplatesState): EmailTemplatesState => ({
      ...state,
      loading: updateLoadingState(state.loading, {
        operation: AsyncOperations.deleteEmailTemplate,
        status: LoadingStatuses.Failed,
        message: 'There was an error trying to delete email template.'
      })
    })
  ),
  on(
    deleteEmailTemplatesActions.resetDeleteEmailTemplateLoadingState,
    (state: EmailTemplatesState): EmailTemplatesState => ({
      ...state,
      loading: updateLoadingState(state.loading, {
        operation: AsyncOperations.deleteEmailTemplate,
        status: LoadingStatuses.NotStarted
      })
    })
  )
]
