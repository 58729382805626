import { LoadingStatuses, updateLoadingState } from '@navix/shared/loading'
import { on } from '@ngrx/store'
import { DisputeReasonGroupAsyncOperations } from '../../../domain/dispute-reason-groups/dispute-reasons-loading.model'
import { DisputeReasonGroupsState } from '../dispute-reason-groups.reducer'
import { updateDisputeReasonGroupActions } from './update-dispute-reason-group.actions'

export const updateDisputeReasonGroupReducers = [
  on(
    updateDisputeReasonGroupActions.updateDisputeReasonGroup,
    (state: DisputeReasonGroupsState) => ({
      ...state,
      loading: updateLoadingState(state.loading, {
        operation: DisputeReasonGroupAsyncOperations.updateDisputeReasonGroup,
        status: LoadingStatuses.InProgress
      })
    })
  ),
  on(
    updateDisputeReasonGroupActions.updateDisputeReasonGroupSuccess,
    (state: DisputeReasonGroupsState): DisputeReasonGroupsState => ({
      ...state,
      loading: updateLoadingState(state.loading, {
        operation: DisputeReasonGroupAsyncOperations.updateDisputeReasonGroup,
        status: LoadingStatuses.Completed,
        message: 'Dispute reason group updated successfully.'
      })
    })
  ),
  on(
    updateDisputeReasonGroupActions.updateDisputeReasonGroupFail,
    (state: DisputeReasonGroupsState): DisputeReasonGroupsState => ({
      ...state,
      loading: updateLoadingState(state.loading, {
        operation: DisputeReasonGroupAsyncOperations.updateDisputeReasonGroup,
        status: LoadingStatuses.Failed,
        message: 'There was an error trying to update dispute reason group.'
      })
    })
  ),
  on(
    updateDisputeReasonGroupActions.resetUpdateDisputeReasonGroupLoadingState,
    (state: DisputeReasonGroupsState): DisputeReasonGroupsState => ({
      ...state,
      loading: updateLoadingState(state.loading, {
        operation: DisputeReasonGroupAsyncOperations.updateDisputeReasonGroup,
        status: LoadingStatuses.NotStarted
      })
    })
  )
]
