import { LoadingState, getDefaultLoadingState } from '@navix/shared/loading'
import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity'
import { Action, createReducer } from '@ngrx/store'
import { DisputeReason } from '../../domain/dispute-reasons/dispute-reason.model'
import { DisputeReasonAsyncOperations } from '../../domain/dispute-reasons/dispute-reasons-loading.model'
import { addDisputeReasonsReducers } from './add-dispute-reasons/add-dispute-reasons.reducer'
import { loadDisputeReasonsReducers } from './load-dispute-reasons/load-dispute-reasons.reducer'
import { updateDisputeReasonReducers } from './update-dispute-reason/update-dispute-reason.reducer'

export const disputeReasonsAdapter: EntityAdapter<DisputeReason> =
  createEntityAdapter<DisputeReason>()

export const DISPUTE_REASONS_FEATURE_KEY = 'feature-dispute-reasons'

export interface DisputeReasonsState extends EntityState<DisputeReason> {
  loading: LoadingState<typeof DisputeReasonAsyncOperations>
}

export const initialDisputeReasonsState: DisputeReasonsState =
  disputeReasonsAdapter.getInitialState({
    loading: getDefaultLoadingState(DisputeReasonAsyncOperations)
  })

const reducer = createReducer(
  initialDisputeReasonsState,
  ...loadDisputeReasonsReducers,
  ...addDisputeReasonsReducers,
  ...updateDisputeReasonReducers
)

export function disputeReasonsReducer(
  state: DisputeReasonsState,
  action: Action
) {
  return reducer(state, action)
}
