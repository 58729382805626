import { createActionGroup, emptyProps, props } from '@ngrx/store'
import { DisputeReasonGroupForm } from '../../../domain/dispute-reason-groups/dispute-reason-group-form.model'

export const addDisputeReasonGroupsActions = createActionGroup({
  source: 'Dispute Reason Groups - Add',
  events: {
    addDisputeReasonGroups: props<{
      disputeReasonGroups: DisputeReasonGroupForm
    }>(),
    addDisputeReasonGroupsSuccess: emptyProps(),
    addDisputeReasonGroupsFail: props<{ error?: string }>(),
    resetAddDisputeReasonGroupsLoadingState: emptyProps()
  }
})
