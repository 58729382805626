import { platform_v2 } from '@navix/api-models'
import { DisputeReason } from '../domain/dispute-reasons/dispute-reason.model'

export function fromGetDisputeReasonsResponse(
  response: Array<platform_v2.Dispute_Api_DTOs_DisputeReasonResponse>
): DisputeReason[] {
  return response.map(
    disputeReason =>
      ({
        id: disputeReason.uuid ?? '',
        eTag: disputeReason.eTag ?? '',
        party: disputeReason.party as DisputeReason['party'],
        description: disputeReason.description ?? '',
        disputeReasonGroup: disputeReason.disputeReasonGroup,
        disputeReasonGroupId: disputeReason.disputeReasonGroupUuid
      }) as DisputeReason
  )
}
