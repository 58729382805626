import { inject } from '@angular/core'
import { platform_v2 } from '@navix/api-models'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { catchError, debounceTime, exhaustMap, map, of } from 'rxjs'
import { fromGetDisputeReasonsResponse } from '../../../adapter/FromGetDisputeReasonsResponse'
import { MS_BEFORE_RESET_LOADING_STATE } from '../../disputes.constants'
import { loadDisputeReasonsActions } from './load-dispute-reasons.actions'

export const loadDisputeReasons = createEffect(
  (
    actions$ = inject(Actions),
    service = inject(platform_v2.DisputeService)
  ) => {
    return actions$.pipe(
      ofType(loadDisputeReasonsActions.loadDisputeReasons),
      exhaustMap(() =>
        service.getApiV2DisputeReasons().pipe(
          map(response =>
            loadDisputeReasonsActions.loadDisputeReasonsSuccess({
              disputeReasons: fromGetDisputeReasonsResponse(response)
            })
          ),
          catchError(error =>
            of(loadDisputeReasonsActions.loadDisputeReasonsFail({ error }))
          )
        )
      )
    )
  },
  {
    functional: true
  }
)

export const resetGetDisputeReasons = createEffect(
  (actions$ = inject(Actions)) => {
    return actions$.pipe(
      ofType(
        loadDisputeReasonsActions.loadDisputeReasonsSuccess,
        loadDisputeReasonsActions.loadDisputeReasonsFail
      ),
      debounceTime(MS_BEFORE_RESET_LOADING_STATE),
      map(() => loadDisputeReasonsActions.resetGetDisputeReasonsLoadingState())
    )
  },
  {
    functional: true
  }
)
