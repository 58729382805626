import { LoadingStatuses, updateLoadingState } from '@navix/shared/loading'
import { on } from '@ngrx/store'
import { DisputeReasonGroupAsyncOperations } from '../../../domain/dispute-reason-groups/dispute-reasons-loading.model'
import {
  DisputeReasonGroupsState,
  disputeReasonGroupsAdapter
} from '../dispute-reason-groups.reducer'
import { loadDisputeReasonGroupsActions } from './load-dispute-reason-groups.actions'

export const loadDisputeReasonGroupsReducers = [
  on(
    loadDisputeReasonGroupsActions.loadDisputeReasonGroups,
    (state: DisputeReasonGroupsState) => ({
      ...state,
      loading: updateLoadingState(state.loading, {
        operation: DisputeReasonGroupAsyncOperations.loadDisputeReasonGroups,
        status: LoadingStatuses.InProgress
      })
    })
  ),
  on(
    loadDisputeReasonGroupsActions.loadDisputeReasonGroupsSuccess,
    (
      state: DisputeReasonGroupsState,
      { disputeReasonGroups }
    ): DisputeReasonGroupsState =>
      disputeReasonGroupsAdapter.setAll(disputeReasonGroups, {
        ...state,
        loading: updateLoadingState(state.loading, {
          operation: DisputeReasonGroupAsyncOperations.loadDisputeReasonGroups,
          status: LoadingStatuses.Completed
        })
      })
  ),
  on(
    loadDisputeReasonGroupsActions.loadDisputeReasonGroupsFail,
    (state: DisputeReasonGroupsState): DisputeReasonGroupsState => ({
      ...state,
      loading: updateLoadingState(state.loading, {
        operation: DisputeReasonGroupAsyncOperations.loadDisputeReasonGroups,
        status: LoadingStatuses.Failed,
        message: 'There was an error trying to load dispute reasons.'
      })
    })
  ),
  on(
    loadDisputeReasonGroupsActions.resetGetDisputeReasonGroupsLoadingState,
    (state: DisputeReasonGroupsState): DisputeReasonGroupsState => ({
      ...state,
      loading: updateLoadingState(state.loading, {
        operation: DisputeReasonGroupAsyncOperations.loadDisputeReasonGroups,
        status: LoadingStatuses.NotStarted
      })
    })
  )
]
