import { createFeatureSelector, createSelector } from '@ngrx/store'
import {
  EMAIL_TEMPLATES_FEATURE_KEY,
  EmailTemplatesState,
  emailTemplatesAdapter
} from './email-templates/email-templates.reducer'
import {
  DISPUTE_REASONS_FEATURE_KEY,
  DisputeReasonsState,
  disputeReasonsAdapter
} from './dispute-reasons/dispute-reasons.reducer'

//#region Email Templates
export const selectEmailTemplatesState =
  createFeatureSelector<EmailTemplatesState>(EMAIL_TEMPLATES_FEATURE_KEY)

export const selectLoading = createSelector(
  selectEmailTemplatesState,
  (state: EmailTemplatesState) => state.loading
)

const disputesSelectors = emailTemplatesAdapter.getSelectors()

export const selectAllEmailTemplates = createSelector(
  selectEmailTemplatesState,
  (state: EmailTemplatesState) => disputesSelectors.selectAll(state)
)

export const selectDisputesEntities = createSelector(
  selectEmailTemplatesState,
  (state: EmailTemplatesState) => disputesSelectors.selectEntities(state)
)
//#endregion

//#region Dispute Reasons
export const selectDisputeReasonsState =
  createFeatureSelector<DisputeReasonsState>(DISPUTE_REASONS_FEATURE_KEY)

const disputeReasonsSelectors = disputeReasonsAdapter.getSelectors()

export const selectDisputeReasonsLoading = createSelector(
  selectDisputeReasonsState,
  (state: DisputeReasonsState) => state.loading
)

export const selectAllDisputeReasons = createSelector(
  selectDisputeReasonsState,
  (state: DisputeReasonsState) => disputeReasonsSelectors.selectAll(state)
)
//#endregion
