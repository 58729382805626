import { makeEnvironmentProviders } from '@angular/core'
import { provideEffects } from '@ngrx/effects'
import { provideState } from '@ngrx/store'
import {
  addEmailTemplatesEffects,
  deleteEmailTemplatesEffects,
  loadEmailTemplatesEffects,
  updateEmailTemplatesEffects
} from '../+state/email-templates'

import {
  DISPUTE_REASON_GROUPS_FEATURE_KEY,
  DisputeReasonGroupsFacade,
  addDisputeReasonGroupsEffects,
  disputeReasonGroupsReducer,
  loadDisputeReasonGroupsEffects,
  updateDisputeReasonGroupsEffects
} from '../+state/dispute-reason-groups'
import {
  DisputeReasonsFacade,
  addDisputeReasonsEffects,
  loadDisputeReasonEffects,
  updateDisputeReasonEffects
} from '../+state/dispute-reasons'
import {
  DISPUTE_REASONS_FEATURE_KEY,
  disputeReasonsReducer
} from '../+state/dispute-reasons/dispute-reasons.reducer'
import { DisputesFacade } from '../+state/disputes.facade'
import {
  EMAIL_TEMPLATES_FEATURE_KEY,
  emailTemplatesReducer
} from '../+state/email-templates/email-templates.reducer'

export function provideDisputesFeature() {
  return makeEnvironmentProviders([
    provideEffects(
      loadEmailTemplatesEffects,
      addEmailTemplatesEffects,
      updateEmailTemplatesEffects,
      deleteEmailTemplatesEffects,
      loadDisputeReasonEffects,
      addDisputeReasonsEffects,
      updateDisputeReasonEffects,
      loadDisputeReasonGroupsEffects,
      addDisputeReasonGroupsEffects,
      updateDisputeReasonGroupsEffects
    ),
    provideState(EMAIL_TEMPLATES_FEATURE_KEY, emailTemplatesReducer),
    provideState(DISPUTE_REASONS_FEATURE_KEY, disputeReasonsReducer),
    provideState(DISPUTE_REASON_GROUPS_FEATURE_KEY, disputeReasonGroupsReducer),
    DisputesFacade,
    DisputeReasonsFacade,
    DisputeReasonGroupsFacade
  ])
}
