import { createActionGroup, emptyProps, props } from '@ngrx/store'
import { DisputeReason } from '../../../domain/dispute-reasons/dispute-reason.model'

export const loadDisputeReasonsActions = createActionGroup({
  source: 'Dispute Reasons - Load',
  events: {
    loadDisputeReasons: emptyProps(),
    loadDisputeReasonsSuccess: props<{
      disputeReasons: DisputeReason[]
    }>(),
    loadDisputeReasonsFail: props<{ error: unknown }>(),
    resetGetDisputeReasonsLoadingState: emptyProps()
  }
})
