import { Injectable, Signal, computed, inject } from '@angular/core'
import { loadingStateEqualFn } from '@navix/shared/loading'
import { Store } from '@ngrx/store'
import { DisputeReasonForm } from '../../domain/dispute-reasons/dispute-reason-form.model'
import {
  DisputeReasonAsyncOperations,
  DisputeReasonsLoadingState
} from '../../domain/dispute-reasons/dispute-reasons-loading.model'
import { addDisputeReasonsActions } from './add-dispute-reasons/add-dispute-reasons.actions'
import * as DisputeReasonsSelectors from './dispute-reasons.selectors'
import { loadDisputeReasonsActions } from './load-dispute-reasons/load-dispute-reasons.actions'
import { DisputeReason } from '../../domain/dispute-reasons/dispute-reason.model'
import { updateDisputeReasonActions } from './update-dispute-reason/update-dispute-reason.actions'

@Injectable()
export class DisputeReasonsFacade {
  private readonly store: Store = inject(Store)

  loading = this.store.selectSignal(DisputeReasonsSelectors.selectLoading)

  allDisputeReasons = this.store.selectSignal(
    DisputeReasonsSelectors.selectAllDisputeReasons
  )

  getLoadingStateSignal<K extends keyof typeof DisputeReasonAsyncOperations>(
    operation: K
  ) {
    return computed(() => this.loading()[operation], {
      equal: loadingStateEqualFn
    }) satisfies Signal<DisputeReasonsLoadingState[K]>
  }

  loadDisputeReasons() {
    this.store.dispatch(loadDisputeReasonsActions.loadDisputeReasons())
  }

  addDisputeReasons(disputeReasons: DisputeReasonForm) {
    this.store.dispatch(
      addDisputeReasonsActions.addDisputeReasons({
        disputeReasons
      })
    )
  }

  updateDisputeReasonGroup(disputeReason: DisputeReason) {
    this.store.dispatch(
      updateDisputeReasonActions.updateDisputeReason({
        disputeReason
      })
    )
  }
}
