import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity'
import {
  LoadingState,
  getDefaultLoadingState,
  updateLoadingState
} from '@navix/shared/loading'
import { Action, createReducer, on } from '@ngrx/store'
import { EmailTemplate } from '../../domain/email-template.model'
import { AsyncOperations } from '../../domain/disputes-loading.model'
import { loadEmailTemplatesReducers } from './load-email-templates/load-email-templates.reducer'
import { addEmailTemplatesReducers } from './add-email-templates/add-email-templates.reducer'
import { updateEmailTemplatesReducers } from './update-email-templates/update-email-templates.reducer'
import { deleteEmailTemplatesReducers } from './delete-email-templates/delete-email-templates.reducer'

export const emailTemplatesAdapter: EntityAdapter<EmailTemplate> =
  createEntityAdapter<EmailTemplate>()

export const EMAIL_TEMPLATES_FEATURE_KEY = 'feature-email-templates'

export interface EmailTemplatesState extends EntityState<EmailTemplate> {
  loading: LoadingState<typeof AsyncOperations>
  emailTemplates: EntityState<EmailTemplate>
}

export const initialEmailTemplatesState: EmailTemplatesState =
  emailTemplatesAdapter.getInitialState({
    loading: getDefaultLoadingState(AsyncOperations),
    emailTemplates: emailTemplatesAdapter.getInitialState()
  })

const reducer = createReducer(
  initialEmailTemplatesState,
  ...loadEmailTemplatesReducers,
  ...addEmailTemplatesReducers,
  ...updateEmailTemplatesReducers,
  ...deleteEmailTemplatesReducers,
  on(
    (
      state: EmailTemplatesState,
      { operation, loading, message }
    ): EmailTemplatesState => ({
      ...state,
      loading: updateLoadingState(state.loading, {
        operation,
        status: loading,
        message: message ?? ''
      })
    })
  )
)

export function emailTemplatesReducer(
  state: EmailTemplatesState,
  action: Action
) {
  return reducer(state, action)
}
