import { inject } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { catchError, debounceTime, exhaustMap, map, of } from 'rxjs'
import { DisputesService } from '../../../infrastructure/disputes.service'
import { MS_BEFORE_RESET_LOADING_STATE } from '../../disputes.constants'
import { addEmailTemplatesActions } from './add-email-templates.actions'

export const addEmailTemplate = createEffect(
  (actions$ = inject(Actions), service = inject(DisputesService)) => {
    return actions$.pipe(
      ofType(addEmailTemplatesActions.addEmailTemplate),
      map(({ emailTemplate }) => ({
        description: emailTemplate.name,
        template: emailTemplate.message,
        subject: emailTemplate.subject,
        party: emailTemplate.party,
        type: emailTemplate.type
      })),
      exhaustMap(request =>
        service.addEmailTemplate(request).pipe(
          map(() => addEmailTemplatesActions.addEmailTemplateSuccess()),
          catchError(error =>
            of(addEmailTemplatesActions.addEmailTemplateFail({ error }))
          )
        )
      )
    )
  },
  {
    functional: true
  }
)

export const resetAddEmailTemplate = createEffect(
  (actions$ = inject(Actions)) => {
    return actions$.pipe(
      ofType(
        addEmailTemplatesActions.addEmailTemplateSuccess,
        addEmailTemplatesActions.addEmailTemplateFail
      ),
      debounceTime(MS_BEFORE_RESET_LOADING_STATE),
      map(() => addEmailTemplatesActions.resetAddEmailTemplateLoadingState())
    )
  },
  {
    functional: true
  }
)
