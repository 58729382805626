import { on } from '@ngrx/store'
import { LoadingStatuses, updateLoadingState } from '@navix/shared/loading'
import { AsyncOperations } from '../../../domain/disputes-loading.model'
import { updateEmailTemplatesActions } from './update-email-templates.actions'
import { EmailTemplatesState } from '../email-templates.reducer'

export const updateEmailTemplatesReducers = [
  on(
    updateEmailTemplatesActions.updateEmailTemplate,
    (state: EmailTemplatesState) => ({
      ...state,
      loading: updateLoadingState(state.loading, {
        operation: AsyncOperations.updateEmailTemplate,
        status: LoadingStatuses.InProgress
      })
    })
  ),
  on(
    updateEmailTemplatesActions.updateEmailTemplateSuccess,
    (state: EmailTemplatesState): EmailTemplatesState => ({
      ...state,
      loading: updateLoadingState(state.loading, {
        operation: AsyncOperations.updateEmailTemplate,
        status: LoadingStatuses.Completed,
        message: 'Email template updated successfully.'
      })
    })
  ),
  on(
    updateEmailTemplatesActions.updateEmailTemplateFail,
    (state: EmailTemplatesState): EmailTemplatesState => ({
      ...state,
      loading: updateLoadingState(state.loading, {
        operation: AsyncOperations.updateEmailTemplate,
        status: LoadingStatuses.Failed,
        message: 'There was an error trying to update email template.'
      })
    })
  ),
  on(
    updateEmailTemplatesActions.resetUpdateEmailTemplateLoadingState,
    (state: EmailTemplatesState): EmailTemplatesState => ({
      ...state,
      loading: updateLoadingState(state.loading, {
        operation: AsyncOperations.updateEmailTemplate,
        status: LoadingStatuses.NotStarted
      })
    })
  )
]
