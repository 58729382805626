import { createActionGroup, emptyProps, props } from '@ngrx/store'
import { EmailTemplate } from '../../../domain/email-template.model'

export const addEmailTemplatesActions = createActionGroup({
  source: 'Email Templates - Add',
  events: {
    addEmailTemplate: props<{
      emailTemplate: EmailTemplate
    }>(),
    addEmailTemplateSuccess: emptyProps(),
    addEmailTemplateFail: props<{ error: unknown }>(),
    resetAddEmailTemplateLoadingState: emptyProps()
  }
})
