import { HttpErrorResponse } from '@angular/common/http'
import { inject } from '@angular/core'
import { platform_v2 } from '@navix/api-models'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { catchError, debounceTime, exhaustMap, map, of } from 'rxjs'
import { MS_BEFORE_RESET_LOADING_STATE } from '../../disputes.constants'
import { addDisputeReasonsActions } from './add-dispute-reasons.actions'

export const addDisputeReasons = createEffect(
  (
    actions$ = inject(Actions),
    service = inject(platform_v2.DisputeService)
  ) => {
    return actions$.pipe(
      ofType(addDisputeReasonsActions.addDisputeReasons),
      exhaustMap(({ disputeReasons }) =>
        service
          .postApiV2DisputeReasons({
            requestBody: {
              disputeReasons: disputeReasons.map(x => {
                return {
                  party: x.party,
                  description: x.description,
                  disputeReasonGroupUuid: x.disputeReasonGroupId
                }
              })
            } as platform_v2.Navix_Services_Sdk_DisputeReasonsCreationDTO
          })
          .pipe(
            map(() => addDisputeReasonsActions.addDisputeReasonsSuccess()),
            catchError(error => {
              let errorMessage
              if (error instanceof HttpErrorResponse) {
                if (error.status === 409) {
                  errorMessage = 'Some dispute reasons already exist.'
                } else {
                  errorMessage =
                    'An error occurred while processing your request.'
                }
              }
              console.error(
                'There was an error trying to load dispute reasons',
                error
              )
              return of(
                addDisputeReasonsActions.addDisputeReasonsFail({
                  error: errorMessage
                })
              )
            })
          )
      )
    )
  },
  {
    functional: true
  }
)

export const resetAddDisputeReasons = createEffect(
  (actions$ = inject(Actions)) => {
    return actions$.pipe(
      ofType(
        addDisputeReasonsActions.addDisputeReasonsSuccess,
        addDisputeReasonsActions.addDisputeReasonsFail
      ),
      debounceTime(MS_BEFORE_RESET_LOADING_STATE),
      map(() => addDisputeReasonsActions.resetAddDisputeReasonsLoadingState())
    )
  },
  {
    functional: true
  }
)
