import { inject } from '@angular/core'
import { platform_v2 } from '@navix/api-models'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { catchError, debounceTime, exhaustMap, map, of } from 'rxjs'
import { MS_BEFORE_RESET_LOADING_STATE } from '../../disputes.constants'
import { updateDisputeReasonActions } from './update-dispute-reason.actions'

export const updateDisputeReason = createEffect(
  (
    actions$ = inject(Actions),
    service = inject(platform_v2.DisputeService)
  ) => {
    return actions$.pipe(
      ofType(updateDisputeReasonActions.updateDisputeReason),
      map(({ disputeReason }) => ({
        disputeReasonUuid: disputeReason.id,
        requestBody: {
          eTag: disputeReason.eTag,
          description: disputeReason.description,
          party: disputeReason.party,
          disputeReasonGroupUuid: disputeReason.disputeReasonGroupId
        }
      })),
      exhaustMap(({ disputeReasonUuid, requestBody }) =>
        service.putApiV2DisputeReasons({ disputeReasonUuid, requestBody }).pipe(
          map(() => updateDisputeReasonActions.updateDisputeReasonSuccess()),
          catchError(error =>
            of(
              updateDisputeReasonActions.updateDisputeReasonFail({
                error
              })
            )
          )
        )
      )
    )
  },
  {
    functional: true
  }
)

export const resetUpdateDisputeReason = createEffect(
  (actions$ = inject(Actions)) => {
    return actions$.pipe(
      ofType(
        updateDisputeReasonActions.updateDisputeReasonSuccess,
        updateDisputeReasonActions.updateDisputeReasonFail
      ),
      debounceTime(MS_BEFORE_RESET_LOADING_STATE),
      map(() =>
        updateDisputeReasonActions.resetUpdateDisputeReasonLoadingState()
      )
    )
  },
  {
    functional: true
  }
)
