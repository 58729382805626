import { on } from '@ngrx/store'
import { loadEmailTemplatesActions } from './load-email-templates.actions'
import { LoadingStatuses, updateLoadingState } from '@navix/shared/loading'
import { AsyncOperations } from '../../../domain/disputes-loading.model'
import {
  EmailTemplatesState,
  emailTemplatesAdapter
} from '../email-templates.reducer'

export const loadEmailTemplatesReducers = [
  on(
    loadEmailTemplatesActions.loadEmailTemplates,
    (state: EmailTemplatesState) => ({
      ...state,
      loading: updateLoadingState(state.loading, {
        operation: AsyncOperations.loadEmailTemplates,
        status: LoadingStatuses.InProgress
      })
    })
  ),
  on(
    loadEmailTemplatesActions.loadEmailTemplatesSuccess,
    (state: EmailTemplatesState, { emailTemplates }): EmailTemplatesState =>
      emailTemplatesAdapter.setAll(emailTemplates, {
        ...state,
        loading: updateLoadingState(state.loading, {
          operation: AsyncOperations.loadEmailTemplates,
          status: LoadingStatuses.Completed
        })
      })
  ),
  on(
    loadEmailTemplatesActions.loadEmailTemplatesFail,
    (state: EmailTemplatesState): EmailTemplatesState => ({
      ...state,
      loading: updateLoadingState(state.loading, {
        operation: AsyncOperations.loadEmailTemplates,
        status: LoadingStatuses.Failed,
        message: 'There was an error trying to load email templates.'
      })
    })
  ),
  on(
    loadEmailTemplatesActions.resetGetEmailTemplatesLoadingState,
    (state: EmailTemplatesState): EmailTemplatesState => ({
      ...state,
      loading: updateLoadingState(state.loading, {
        operation: AsyncOperations.loadEmailTemplates,
        status: LoadingStatuses.NotStarted
      })
    })
  )
]
