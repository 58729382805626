import { inject } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { DisputesService } from '../../../infrastructure/disputes.service'
import { catchError, debounceTime, exhaustMap, map, of } from 'rxjs'
import { deleteEmailTemplatesActions } from './delete-email-templates.actions'
import { MS_BEFORE_RESET_LOADING_STATE } from '../../disputes.constants'

export const deleteEmailTemplate = createEffect(
  (actions$ = inject(Actions), service = inject(DisputesService)) => {
    return actions$.pipe(
      ofType(deleteEmailTemplatesActions.deleteEmailTemplate),
      exhaustMap(({ id }) =>
        service.deleteEmailTemplate(id).pipe(
          map(() => deleteEmailTemplatesActions.deleteEmailTemplateSuccess()),
          catchError(error =>
            of(deleteEmailTemplatesActions.deleteEmailTemplateFail({ error }))
          )
        )
      )
    )
  },
  {
    functional: true
  }
)

export const resetDeleteEmailTemplate = createEffect(
  (actions$ = inject(Actions)) => {
    return actions$.pipe(
      ofType(
        deleteEmailTemplatesActions.deleteEmailTemplateSuccess,
        deleteEmailTemplatesActions.deleteEmailTemplateFail
      ),
      debounceTime(MS_BEFORE_RESET_LOADING_STATE),
      map(() =>
        deleteEmailTemplatesActions.resetDeleteEmailTemplateLoadingState()
      )
    )
  },
  {
    functional: true
  }
)
