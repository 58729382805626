import { LoadingState, getDefaultLoadingState } from '@navix/shared/loading'
import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity'
import { Action, createReducer } from '@ngrx/store'
import { DisputeReasonGroup } from '../../domain/dispute-reason-groups/dispute-reason-group.model'
import { DisputeReasonGroupAsyncOperations } from '../../domain/dispute-reason-groups/dispute-reasons-loading.model'
import { addDisputeReasonGroupsReducers } from './add-dispute-reason-groups/add-dispute-reason-groups.reducer'
import { loadDisputeReasonGroupsReducers } from './load-dispute-reason-groups/load-dispute-reason-groups.reducer'
import { updateDisputeReasonGroupReducers } from './update-dispute-reason-group/update-dispute-reason-group.reducer'

export const disputeReasonGroupsAdapter: EntityAdapter<DisputeReasonGroup> =
  createEntityAdapter<DisputeReasonGroup>()

export const DISPUTE_REASON_GROUPS_FEATURE_KEY = 'feature-dispute-reason-groups'

export interface DisputeReasonGroupsState
  extends EntityState<DisputeReasonGroup> {
  loading: LoadingState<typeof DisputeReasonGroupAsyncOperations>
}

export const initialDisputeReasonGroupsState: DisputeReasonGroupsState =
  disputeReasonGroupsAdapter.getInitialState({
    loading: getDefaultLoadingState(DisputeReasonGroupAsyncOperations)
  })

const reducer = createReducer(
  initialDisputeReasonGroupsState,
  ...loadDisputeReasonGroupsReducers,
  ...addDisputeReasonGroupsReducers,
  ...updateDisputeReasonGroupReducers
)

export function disputeReasonGroupsReducer(
  state: DisputeReasonGroupsState,
  action: Action
) {
  return reducer(state, action)
}
