import { inject } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { catchError, debounceTime, exhaustMap, map, of } from 'rxjs'
import { DisputesService } from '../../../infrastructure/disputes.service'
import { MS_BEFORE_RESET_LOADING_STATE } from '../../disputes.constants'
import { updateEmailTemplatesActions } from './update-email-templates.actions'

export const updateEmailTemplate = createEffect(
  (actions$ = inject(Actions), service = inject(DisputesService)) => {
    return actions$.pipe(
      ofType(updateEmailTemplatesActions.updateEmailTemplate),
      map(({ emailTemplate }) => ({
        emailtemplateId: emailTemplate.id,
        request: {
          description: emailTemplate.name,
          template: emailTemplate.message,
          eTag: emailTemplate.eTag,
          subject: emailTemplate.subject,
          party: emailTemplate.party,
          type: emailTemplate.type
        }
      })),
      exhaustMap(({ emailtemplateId, request }) =>
        service.updateEmailTemplate(emailtemplateId, request).pipe(
          map(() => updateEmailTemplatesActions.updateEmailTemplateSuccess()),
          catchError(error =>
            of(updateEmailTemplatesActions.updateEmailTemplateFail({ error }))
          )
        )
      )
    )
  },
  {
    functional: true
  }
)

export const resetUpdateEmailTemplate = createEffect(
  (actions$ = inject(Actions)) => {
    return actions$.pipe(
      ofType(
        updateEmailTemplatesActions.updateEmailTemplateSuccess,
        updateEmailTemplatesActions.updateEmailTemplateFail
      ),
      debounceTime(MS_BEFORE_RESET_LOADING_STATE),
      map(() =>
        updateEmailTemplatesActions.resetUpdateEmailTemplateLoadingState()
      )
    )
  },
  {
    functional: true
  }
)
