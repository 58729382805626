import { inject } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { catchError, debounceTime, exhaustMap, map, of } from 'rxjs'
import { DisputesService } from '../../../infrastructure/disputes.service'
import { loadEmailTemplatesActions } from './load-email-templates.actions'
import { fromGetEmailTemplatesResponse } from '../../../adapter/FromGetEmailTemplatesResponse'
import { MS_BEFORE_RESET_LOADING_STATE } from '../../disputes.constants'

export const loadEmailTemplates = createEffect(
  (actions$ = inject(Actions), service = inject(DisputesService)) => {
    return actions$.pipe(
      ofType(loadEmailTemplatesActions.loadEmailTemplates),
      exhaustMap(() =>
        service.getEmailTemplates().pipe(
          map(response =>
            loadEmailTemplatesActions.loadEmailTemplatesSuccess({
              emailTemplates: fromGetEmailTemplatesResponse(response)
            })
          ),
          map(({ emailTemplates }) =>
            loadEmailTemplatesActions.loadEmailTemplatesSuccess({
              emailTemplates
            })
          ),
          catchError(error =>
            of(loadEmailTemplatesActions.loadEmailTemplatesFail({ error }))
          )
        )
      )
    )
  },
  {
    functional: true
  }
)

export const resetGetEmailTemplates = createEffect(
  (actions$ = inject(Actions)) => {
    return actions$.pipe(
      ofType(
        loadEmailTemplatesActions.loadEmailTemplatesSuccess,
        loadEmailTemplatesActions.loadEmailTemplatesFail
      ),
      debounceTime(MS_BEFORE_RESET_LOADING_STATE),
      map(() => loadEmailTemplatesActions.resetGetEmailTemplatesLoadingState())
    )
  },
  {
    functional: true
  }
)
