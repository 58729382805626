import { LoadingState } from '@navix/shared/loading'

export enum AsyncOperations {
  loadEmailTemplates = 'loadEmailTemplates',
  addEmailTemplate = 'addEmailTemplate',
  updateEmailTemplate = 'updateEmailTemplate',
  deleteEmailTemplate = 'deleteEmailTemplate'
}

export type DisputesLoadingState = LoadingState<typeof AsyncOperations>
