import { inject } from '@angular/core'
import { platform_v2 } from '@navix/api-models'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { catchError, debounceTime, exhaustMap, map, of } from 'rxjs'
import { MS_BEFORE_RESET_LOADING_STATE } from '../../disputes.constants'
import { updateDisputeReasonGroupActions } from './update-dispute-reason-group.actions'

export const updateDisputeReasonGroup = createEffect(
  (
    actions$ = inject(Actions),
    service = inject(platform_v2.DisputeService)
  ) => {
    return actions$.pipe(
      ofType(updateDisputeReasonGroupActions.updateDisputeReasonGroup),
      map(({ disputeReasonGroup }) => ({
        disputeReasonGroupUuid: disputeReasonGroup.id,
        requestBody: {
          description: disputeReasonGroup.description,
          allowEmail: disputeReasonGroup.allowEmail,
          eTag: disputeReasonGroup.eTag
        }
      })),
      exhaustMap(({ disputeReasonGroupUuid, requestBody }) =>
        service
          .putApiV2DisputeReasonGroups({ disputeReasonGroupUuid, requestBody })
          .pipe(
            map(() =>
              updateDisputeReasonGroupActions.updateDisputeReasonGroupSuccess()
            ),
            catchError(error =>
              of(
                updateDisputeReasonGroupActions.updateDisputeReasonGroupFail({
                  error
                })
              )
            )
          )
      )
    )
  },
  {
    functional: true
  }
)

export const resetUpdateDisputeReasonGroup = createEffect(
  (actions$ = inject(Actions)) => {
    return actions$.pipe(
      ofType(
        updateDisputeReasonGroupActions.updateDisputeReasonGroupSuccess,
        updateDisputeReasonGroupActions.updateDisputeReasonGroupFail
      ),
      debounceTime(MS_BEFORE_RESET_LOADING_STATE),
      map(() =>
        updateDisputeReasonGroupActions.resetUpdateDisputeReasonGroupLoadingState()
      )
    )
  },
  {
    functional: true
  }
)
