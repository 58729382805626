import { EmailTemplate } from '../domain/email-template.model'
import { GetEmailTemplatesResponse } from '../domain/get-email-templates.response'

export function fromGetEmailTemplatesResponse(
  response: GetEmailTemplatesResponse
): EmailTemplate[] {
  return response
    .map(template => ({
      id: template.uuid,
      eTag: template.eTag,
      name: template.description,
      subject: template.subject ?? '',
      message: template.template,
      party: template.party as EmailTemplate['party'],
      type: template.type
    }))
    .toSorted(
      (compare, comparer) =>
        compare.name.localeCompare(comparer.name) ||
        compare.type.localeCompare(comparer.type)
    )
}
