import { createActionGroup, emptyProps, props } from '@ngrx/store'
import { DisputeReasonGroup } from '../../../domain/dispute-reason-groups/dispute-reason-group.model'

export const loadDisputeReasonGroupsActions = createActionGroup({
  source: 'Dispute Reason Groups - Load',
  events: {
    loadDisputeReasonGroups: emptyProps(),
    loadDisputeReasonGroupsSuccess: props<{
      disputeReasonGroups: DisputeReasonGroup[]
    }>(),
    loadDisputeReasonGroupsFail: props<{ error: unknown }>(),
    resetGetDisputeReasonGroupsLoadingState: emptyProps()
  }
})
