import { createFeatureSelector, createSelector } from '@ngrx/store'
import {
  DISPUTE_REASONS_FEATURE_KEY,
  DisputeReasonsState,
  disputeReasonsAdapter
} from './dispute-reasons.reducer'

export const selectDisputeReasonsState =
  createFeatureSelector<DisputeReasonsState>(DISPUTE_REASONS_FEATURE_KEY)

const { selectAll } = disputeReasonsAdapter.getSelectors()

export const selectLoading = createSelector(
  selectDisputeReasonsState,
  (state: DisputeReasonsState) => state.loading
)

export const selectAllDisputeReasons = createSelector(
  selectDisputeReasonsState,
  (state: DisputeReasonsState) => selectAll(state)
)
