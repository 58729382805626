import { createActionGroup, emptyProps, props } from '@ngrx/store'
import { EmailTemplate } from '../../../domain/email-template.model'

export const loadEmailTemplatesActions = createActionGroup({
  source: 'Email Templates - Load',
  events: {
    loadEmailTemplates: emptyProps(),
    loadEmailTemplatesSuccess: props<{
      emailTemplates: EmailTemplate[]
    }>(),
    loadEmailTemplatesFail: props<{ error: unknown }>(),
    resetGetEmailTemplatesLoadingState: emptyProps()
  }
})
