import { Injectable, Signal, computed, inject } from '@angular/core'
import { loadingStateEqualFn } from '@navix/shared/loading'
import { Store } from '@ngrx/store'
import { filter } from 'rxjs'
import {
  AsyncOperations,
  DisputesLoadingState
} from '../domain/disputes-loading.model'
import { EmailTemplate } from '../domain/email-template.model'
import * as DisputesSelectors from './disputes.selectors'
import { addEmailTemplatesActions } from './email-templates/add-email-templates/add-email-templates.actions'
import { deleteEmailTemplatesActions } from './email-templates/delete-email-templates/delete-email-templates.actions'
import { loadEmailTemplatesActions } from './email-templates/load-email-templates/load-email-templates.actions'
import { updateEmailTemplatesActions } from './email-templates/update-email-templates/update-email-templates.actions'

@Injectable()
export class DisputesFacade {
  private readonly store: Store = inject(Store)

  //#region Email Templates
  loading = this.store.selectSignal(DisputesSelectors.selectLoading)

  allEmailTemplates$ = this.store
    .select(DisputesSelectors.selectAllEmailTemplates)
    .pipe(filter(data => data.length > 0))

  allEmailTemplates = this.store.selectSignal(
    DisputesSelectors.selectAllEmailTemplates
  )

  getLoadingStateSignal<K extends keyof typeof AsyncOperations>(operation: K) {
    return computed(() => this.loading()[operation], {
      equal: loadingStateEqualFn
    }) satisfies Signal<DisputesLoadingState[K]>
  }

  loadEmailTemplates() {
    this.store.dispatch(loadEmailTemplatesActions.loadEmailTemplates())
  }

  addEmailTemplate(emailTemplate: EmailTemplate) {
    this.store.dispatch(
      addEmailTemplatesActions.addEmailTemplate({ emailTemplate })
    )
  }

  updateEmailTemplate(emailTemplate: EmailTemplate) {
    this.store.dispatch(
      updateEmailTemplatesActions.updateEmailTemplate({ emailTemplate })
    )
  }

  deleteEmailTemplate(emailTemplateId: string) {
    this.store.dispatch(
      deleteEmailTemplatesActions.deleteEmailTemplate({ id: emailTemplateId })
    )
  }
  //#endregion
}
