import { createActionGroup, emptyProps, props } from '@ngrx/store'
import { DisputeReasonForm } from '../../../domain/dispute-reasons/dispute-reason-form.model'

export const addDisputeReasonsActions = createActionGroup({
  source: 'Dispute Reasons - Add',
  events: {
    addDisputeReasons: props<{
      disputeReasons: DisputeReasonForm
    }>(),
    addDisputeReasonsSuccess: emptyProps(),
    addDisputeReasonsFail: props<{ error?: string }>(),
    resetAddDisputeReasonsLoadingState: emptyProps()
  }
})
