import { on } from '@ngrx/store'
import { LoadingStatuses, updateLoadingState } from '@navix/shared/loading'
import { AsyncOperations } from '../../../domain/disputes-loading.model'
import { EmailTemplatesState } from '../email-templates.reducer'
import { addEmailTemplatesActions } from './add-email-templates.actions'

export const addEmailTemplatesReducers = [
  on(
    addEmailTemplatesActions.addEmailTemplate,
    (state: EmailTemplatesState) => ({
      ...state,
      loading: updateLoadingState(state.loading, {
        operation: AsyncOperations.addEmailTemplate,
        status: LoadingStatuses.InProgress
      })
    })
  ),
  on(
    addEmailTemplatesActions.addEmailTemplateSuccess,
    (state: EmailTemplatesState): EmailTemplatesState => ({
      ...state,
      loading: updateLoadingState(state.loading, {
        operation: AsyncOperations.addEmailTemplate,
        status: LoadingStatuses.Completed,
        message: 'Email template added successfully.'
      })
    })
  ),
  on(
    addEmailTemplatesActions.addEmailTemplateFail,
    (state: EmailTemplatesState): EmailTemplatesState => ({
      ...state,
      loading: updateLoadingState(state.loading, {
        operation: AsyncOperations.addEmailTemplate,
        status: LoadingStatuses.Failed,
        message: 'There was an error trying to add email template.'
      })
    })
  ),
  on(
    addEmailTemplatesActions.resetAddEmailTemplateLoadingState,
    (state: EmailTemplatesState): EmailTemplatesState => ({
      ...state,
      loading: updateLoadingState(state.loading, {
        operation: AsyncOperations.addEmailTemplate,
        status: LoadingStatuses.NotStarted
      })
    })
  )
]
