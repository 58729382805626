import { Injectable, inject } from '@angular/core'
import { NonNullableFormBuilder, Validators } from '@angular/forms'
import { DisputeFormControls } from '../domain/disputes-form.model'
import { Party } from '../domain/party.model'

@Injectable({
  providedIn: 'root'
})
export class DisputeFormService {
  private readonly fb = inject(NonNullableFormBuilder)

  getEmailTemplateForm(): DisputeFormControls['emailTemplate'] {
    return this.fb.group({
      id: this.fb.control<string | undefined>(undefined),
      name: this.fb.control<string>('', Validators.required),
      party: this.fb.control<Party>('Tenant', Validators.required),
      eTag: this.fb.control<string>(''),
      type: this.fb.control<string>('Initial', Validators.required),
      subject: this.fb.control<string>('', Validators.required),
      message: this.fb.control<string>('', Validators.required)
    })
  }
}
