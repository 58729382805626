import { createFeatureSelector, createSelector } from '@ngrx/store'
import {
  DISPUTE_REASON_GROUPS_FEATURE_KEY,
  DisputeReasonGroupsState,
  disputeReasonGroupsAdapter
} from './dispute-reason-groups.reducer'

export const selectDisputeReasonGroupsState =
  createFeatureSelector<DisputeReasonGroupsState>(
    DISPUTE_REASON_GROUPS_FEATURE_KEY
  )

const { selectAll } = disputeReasonGroupsAdapter.getSelectors()

export const selectLoading = createSelector(
  selectDisputeReasonGroupsState,
  (state: DisputeReasonGroupsState) => state.loading
)

export const selectAllDisputeReasonGroups = createSelector(
  selectDisputeReasonGroupsState,
  (state: DisputeReasonGroupsState) => selectAll(state)
)
