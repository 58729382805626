import { platform_v2 } from '@navix/api-models'
import { DisputeReasonGroup } from '../domain/dispute-reason-groups/dispute-reason-group.model'

export function fromGetDisputeReasonGroupsResponse(
  response: Array<platform_v2.Navix_Services_Sdk_DisputeReasonGroup>
): DisputeReasonGroup[] {
  return response.map(disputeReason => ({
    id: disputeReason.uuid ?? '',
    eTag: disputeReason.eTag ?? '',
    description: disputeReason.description ?? '',
    allowEmail: disputeReason.allowEmail ?? false
  }))
}
