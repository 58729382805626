import { Injectable, Signal, computed, inject } from '@angular/core'
import { loadingStateEqualFn } from '@navix/shared/loading'
import { Store } from '@ngrx/store'
import { DisputeReasonGroupForm } from '../../domain/dispute-reason-groups/dispute-reason-group-form.model'
import { DisputeReasonGroup } from '../../domain/dispute-reason-groups/dispute-reason-group.model'
import {
  DisputeReasonGroupAsyncOperations,
  DisputeReasonGroupsLoadingState
} from '../../domain/dispute-reason-groups/dispute-reasons-loading.model'
import { addDisputeReasonGroupsActions } from './add-dispute-reason-groups/add-dispute-reason-groups.actions'
import * as DisputeReasonGroupsSelectors from './dispute-reason-groups.selectors'
import { loadDisputeReasonGroupsActions } from './load-dispute-reason-groups/load-dispute-reason-groups.actions'
import { updateDisputeReasonGroupActions } from './update-dispute-reason-group/update-dispute-reason-group.actions'

@Injectable()
export class DisputeReasonGroupsFacade {
  private readonly store: Store = inject(Store)

  loading = this.store.selectSignal(DisputeReasonGroupsSelectors.selectLoading)

  allDisputeReasonGroups = this.store.selectSignal(
    DisputeReasonGroupsSelectors.selectAllDisputeReasonGroups
  )

  getLoadingStateSignal<
    K extends keyof typeof DisputeReasonGroupAsyncOperations
  >(operation: K) {
    return computed(() => this.loading()[operation], {
      equal: loadingStateEqualFn
    }) satisfies Signal<DisputeReasonGroupsLoadingState[K]>
  }

  loadDisputeReasonGroups() {
    this.store.dispatch(
      loadDisputeReasonGroupsActions.loadDisputeReasonGroups()
    )
  }

  addDisputeReasonGroups(disputeReasonGroups: DisputeReasonGroupForm) {
    this.store.dispatch(
      addDisputeReasonGroupsActions.addDisputeReasonGroups({
        disputeReasonGroups
      })
    )
  }

  updateDisputeReasonGroup(disputeReasonGroup: DisputeReasonGroup) {
    this.store.dispatch(
      updateDisputeReasonGroupActions.updateDisputeReasonGroup({
        disputeReasonGroup
      })
    )
  }
}
