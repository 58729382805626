import { LoadingStatuses, updateLoadingState } from '@navix/shared/loading'
import { on } from '@ngrx/store'
import { DisputeReasonAsyncOperations } from '../../../domain/dispute-reasons/dispute-reasons-loading.model'
import { DisputeReasonsState } from '../dispute-reasons.reducer'
import { updateDisputeReasonActions } from './update-dispute-reason.actions'

export const updateDisputeReasonReducers = [
  on(
    updateDisputeReasonActions.updateDisputeReason,
    (state: DisputeReasonsState) => ({
      ...state,
      loading: updateLoadingState(state.loading, {
        operation: DisputeReasonAsyncOperations.updateDisputeReason,
        status: LoadingStatuses.InProgress
      })
    })
  ),
  on(
    updateDisputeReasonActions.updateDisputeReasonSuccess,
    (state: DisputeReasonsState): DisputeReasonsState => ({
      ...state,
      loading: updateLoadingState(state.loading, {
        operation: DisputeReasonAsyncOperations.updateDisputeReason,
        status: LoadingStatuses.Completed,
        message: 'Dispute reason group updated successfully.'
      })
    })
  ),
  on(
    updateDisputeReasonActions.updateDisputeReasonFail,
    (state: DisputeReasonsState): DisputeReasonsState => ({
      ...state,
      loading: updateLoadingState(state.loading, {
        operation: DisputeReasonAsyncOperations.updateDisputeReason,
        status: LoadingStatuses.Failed,
        message: 'There was an error trying to update dispute reason group.'
      })
    })
  ),
  on(
    updateDisputeReasonActions.resetUpdateDisputeReasonLoadingState,
    (state: DisputeReasonsState): DisputeReasonsState => ({
      ...state,
      loading: updateLoadingState(state.loading, {
        operation: DisputeReasonAsyncOperations.updateDisputeReason,
        status: LoadingStatuses.NotStarted
      })
    })
  )
]
