import { LoadingState } from '@navix/shared/loading'

export enum DisputeReasonAsyncOperations {
  loadDisputeReasons = 'loadDisputeReasons',
  addDisputeReasons = 'addDisputeReasons',
  updateDisputeReason = 'updateDisputeReason'
}

export type DisputeReasonsLoadingState = LoadingState<
  typeof DisputeReasonAsyncOperations
>
