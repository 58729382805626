import { LoadingStatuses, updateLoadingState } from '@navix/shared/loading'
import { on } from '@ngrx/store'
import { DisputeReasonAsyncOperations } from '../../../domain/dispute-reasons/dispute-reasons-loading.model'
import {
  DisputeReasonsState,
  disputeReasonsAdapter
} from '../dispute-reasons.reducer'
import { loadDisputeReasonsActions } from './load-dispute-reasons.actions'

export const loadDisputeReasonsReducers = [
  on(
    loadDisputeReasonsActions.loadDisputeReasons,
    (state: DisputeReasonsState) => ({
      ...state,
      loading: updateLoadingState(state.loading, {
        operation: DisputeReasonAsyncOperations.loadDisputeReasons,
        status: LoadingStatuses.InProgress
      })
    })
  ),
  on(
    loadDisputeReasonsActions.loadDisputeReasonsSuccess,
    (state: DisputeReasonsState, { disputeReasons }): DisputeReasonsState =>
      disputeReasonsAdapter.setAll(disputeReasons, {
        ...state,
        loading: updateLoadingState(state.loading, {
          operation: DisputeReasonAsyncOperations.loadDisputeReasons,
          status: LoadingStatuses.Completed
        })
      })
  ),
  on(
    loadDisputeReasonsActions.loadDisputeReasonsFail,
    (state: DisputeReasonsState): DisputeReasonsState => ({
      ...state,
      loading: updateLoadingState(state.loading, {
        operation: DisputeReasonAsyncOperations.loadDisputeReasons,
        status: LoadingStatuses.Failed,
        message: 'There was an error trying to load dispute reasons.'
      })
    })
  ),
  on(
    loadDisputeReasonsActions.resetGetDisputeReasonsLoadingState,
    (state: DisputeReasonsState): DisputeReasonsState => ({
      ...state,
      loading: updateLoadingState(state.loading, {
        operation: DisputeReasonAsyncOperations.loadDisputeReasons,
        status: LoadingStatuses.NotStarted
      })
    })
  )
]
