import { Injectable, inject } from '@angular/core'
import {
  FormArray,
  FormGroup,
  NonNullableFormBuilder,
  Validators
} from '@angular/forms'
import { DisputeReasonFormControls } from '../domain/dispute-reasons/dispute-reason-form.model'
import { Party } from '../domain/party.model'

@Injectable({
  providedIn: 'root'
})
export class DisputeReasonsFormService {
  private readonly fb = inject(NonNullableFormBuilder)

  getDisputeReasonsForm(): FormArray<FormGroup<DisputeReasonFormControls>> {
    return this.fb.array<FormGroup<DisputeReasonFormControls>>([
      this.getDisputeReasonForm()
    ])
  }

  getDisputeReasonForm(): FormGroup<DisputeReasonFormControls> {
    return this.fb.group({
      id: this.fb.control<string | null>(null),
      party: this.fb.control<Party>('Tenant', Validators.required),
      description: this.fb.control<string>('', [Validators.required]),
      eTag: this.fb.control<string | null>(null),
      disputeReasonGroupId: this.fb.control<string | null>(null)
    } as DisputeReasonFormControls)
  }
}
