import { createActionGroup, emptyProps, props } from '@ngrx/store'
import { DisputeReasonGroup } from '../../../domain/dispute-reason-groups/dispute-reason-group.model'

export const updateDisputeReasonGroupActions = createActionGroup({
  source: 'Dispute Reason Groups - Update',
  events: {
    updateDisputeReasonGroup: props<{
      disputeReasonGroup: DisputeReasonGroup
    }>(),
    updateDisputeReasonGroupSuccess: emptyProps(),
    updateDisputeReasonGroupFail: props<{ error: unknown }>(),
    resetUpdateDisputeReasonGroupLoadingState: emptyProps()
  }
})
